import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule, enableProdMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PagesModule } from './pages/pages.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthInterceptor } from './interceptors/auth-interceptor';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environment';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ComponentsModule } from './components/components.module';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogService } from 'primeng/dynamicdialog';
import { provideShareButtonsOptions } from 'ngx-sharebuttons';
import { shareIcons } from 'ngx-sharebuttons/icons';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { getRemoteConfig, provideRemoteConfig } from '@angular/fire/remote-config';
if (environment.production) {
	enableProdMode();
}

@NgModule({
	declarations: [AppComponent],
	bootstrap: [AppComponent], imports: [BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		NgxStripeModule.forRoot(environment.stripePublishableKey),
		PagesModule,
		ComponentsModule,
		ToastModule,
		ConfirmDialogModule
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		MessageService,
		ConfirmationService,
		DialogService,
		provideHttpClient(withInterceptorsFromDi()),
		provideShareButtonsOptions(
			shareIcons()
		),
		provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
		provideAuth(() => getAuth()),
		provideAnalytics(() => getAnalytics()),
		ScreenTrackingService,
		UserTrackingService,
		providePerformance(() => getPerformance()),
		provideRemoteConfig(() => getRemoteConfig())
	]
})
export class AppModule { }
